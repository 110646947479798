import React, { useEffect, useState } from "react";

const AnimatedCounter = ({ targetValue, prefix = "", suffix = "" }) => {
  const [currentValue, setCurrentValue] = useState(0);

  useEffect(() => {
    let startValue = 0;
    const duration = 2000; // Animation duration in ms
    const interval = 10; // Interval time in ms
    const steps = Math.ceil(duration / interval); // Total number of steps
    const increment = Math.ceil(targetValue / steps); // Rounded increment per step

    const counter = setInterval(() => {
      startValue += increment;
      if (startValue >= targetValue) {
        clearInterval(counter);
        setCurrentValue(targetValue); // Ensure final value is exact
      } else {
        setCurrentValue(startValue);
      }
    }, interval);

    return () => clearInterval(counter); // Cleanup interval
  }, [targetValue]);

  return (
    <>
      {prefix}
      {currentValue}
      {suffix}
    </>
  );
};

export default AnimatedCounter;
