const appConstants = Object.freeze({
  // Validation patterns
  VALIDATION_PATTERNS: {
    onlyAlphabet: "^[a-zA-Z]+$", // Applicable for alphapet field
    alphaNumeric: "[أ-يa-zA-Z-_()0-9]+", // Applicable for alpha Numeric field
    alphaNumericWithDot: "[أ-يa-zA-Z-_()0-9.]+", // Applicable for alpha Numeric with dot
    alphaNumericWithSpace: "[أ-يa-zA-Z-_()0-9 ]+", // Applicable for alpha Numeric with space
    onlyNumber: "^[0-9]*$", // Applicable for Number field
    alphabetWithSpace: "^[a-zA-Z ]+$", // Applicable for alphapet with space
    phonenumber: "[+0-9 ]+", // Applicable for phone Number field
    email:
      "([a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]{1}[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]*)((@[a-zA-Z-]{2}[a-zA-Z-]*)[\\.](([a-zA-Z]{3}|[a-zA-Z]{2})|([a-zA-Z]{3}|[a-zA-Z]{2}).[a-zA-Z]{2}))", // Applicable for email field
    countryCode: "[+0-9]+", // Applicable for country code field
    password: "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$",
    url: "https?://.+", // Applicable for URL field
    customUrl: /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*\.com(?:\/[^\s/?#]+(?:\?(?:[^\s#&]+(?:=[^\s&]*)?&)*(?:[^\s#&]+(?:=[^\s&]*)?)?)?(?:#[^\s]*)?)?(?:\/[^\s]*)?(?:\/|(?![^\s]))?$/,
    priceFormatPattern: "[0-9.,]+", // Applicable for price format
    colorPickerPattern: "^#[0-9A-Fa-f]{0,6}$",
  },

  pageOffSet: 0,
  pageSize: 20,
  listOffSet: 0,
  listSize: 10,
  maxFileSize: 250,
  restrictedFileTypes: ["exe"],
  intervalTime: 120000,
  commentsIntervalTime: 60000,
  periodic: {
    checkListEditableStages: [
      "TODO",
      "INPROGRESS",
      "QC",
      "DM",
      "TM",
      "TR",
      "MDT",
      "WFA",
      "ONLINE",
    ],
    ticketToolEditableStages: [
      "TODO",
      "INPROGRESS",
      "QC",
      "DM",
      "TM",
      "TR",
      "MDT",
      "WFA",
      "ONLINE",
    ],
    requirementEditableStages: [
      "OPEN",
      "TODO",
      "INPROGRESS",
      "QC",
      "DM",
      "TM",
      "TR",
      "MDT",
      "WFA",
      "ONLINE",
      "NNU",
      "SO",
    ],
    generalFormDataEditableStages: ["OPEN", "TODO", "INPROGRESS"],
    toolFinancialYearEditableStages: ["OPEN", "TODO", "INPROGRESS"],
    toolCurrentReleaseDateEditableStages: [
      "OPEN",
      "TODO",
      "INPROGRESS",
      "QC",
      "DM",
      "TM",
      "TR",
      "MDT",
      "WFA",
      "ONLINE",
      "NNU",
      "SO",
    ],
    toolUpcomingReleaseDateEditableStages: [
      "OPEN",
      "TODO",
      "INPROGRESS",
      "QC",
      "DM",
      "TM",
      "TR",
      "MDT",
      "WFA",
      "ONLINE",
      "NNU",
      "SO",
    ],
    toolSourceLiveLinkEditableStages: ["OPEN", "TODO", "INPROGRESS"],
  },
  agency: {
    requirementEditableStages: ["OPEN", "PR", "OR"],
  },
  irAPP: {
    requirementEditableStages: ["OP", "TD", "ID","BF"],
    moduleStatusPossibleStages: ["ID", "RFQ", "RFPT", "RFCT"],
    createCommonIssueTicketStages: ["ID", "BF", "RFQ", "RFPT", "RFCT"],
    iterationCreationPossibleStages: ["OP", "TD", "RFCT"],
  },
});

export default appConstants;
