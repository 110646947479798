  export const colorCodeCardName = [
    { name: "a", color: "#9B51E0" },
    { name: "b", color: "#FA541C" },
    { name: "c", color: "#FA8C16" },
    { name: "d", color: "#FAAD14" },
    { name: "e", color: "#FADB14" },
    { name: "f", color: "#A0D911" },
    { name: "g", color: "#52C41A" },
    { name: "h", color: "#13C2C2" },
    { name: "i", color: "#1890FF" },
    { name: "j", color: "#2F54EB" },
    { name: "k", color: "#722ED1" },
    { name: "l", color: "#EB2F96" },
    { name: "m", color: "#DA1E28" },
    { name: "n", color: "#D12771" },
    { name: "o", color: "#9B51E0" },
    { name: "p", color: "#9B51E0" },
    { name: "q", color: "#626A6E" },
    { name: "r", color: "#1D70B8" },
    { name: "s", color: "#0B0C0C" },
    { name: "t", color: "#B1B4B6" },
    { name: "u", color: "#1FB6FF" },
    { name: "v", color: "#FF49DB" },
    { name: "w", color: "#13CE66" },
    { name: "x", color: "#FF7849" },
    { name: "y", color: "#1F2D3D" },
    { name: "z", color: "#8492A6" },
  ];
