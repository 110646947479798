import { lazy, Suspense, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Spinner from "components/spinner/spinner.component";
import useAuth from "hooks/useAuth";
import "styles/index.scss";
import Dashboard from "./pages/Dashboard/Dashboard";

/** LAYOUTS */
const UserLayout = lazy(() =>
  import("components/layout/user-layout.component")
);
const DefaultLayout = lazy(() =>
  import("components/layout/default-layout.component")
);
const SettingLayout = lazy(() =>
  import("components/layout/setting-layout.component")
);

/** PAGES */
const Home = lazy(() => import("pages/Home/Home"));
const Teams = lazy(() => import("pages/Teams/Teams"));
const Calendar = lazy(() =>
  import("pages/Calendar/Periodic/CalendarHome")
);
const Customers = lazy(() => import("pages/Customers/Customers"));
const Products = lazy(() => import("pages/Products/Products"));

const Ticket = lazy(() => import("pages/Ticket/Ticket"));
const Login = lazy(() => import("pages/Login/Login"));
const Notification = lazy(() => import("pages/Notification/Notification"));
const UserProfile = lazy(() => import("pages/User/Profile"));
const CustomersDetails = lazy(() =>
  import("pages/Customers/CustomersDetails/CustomersDetails")
);
const ProductsDetails = lazy(() =>
  import("pages/ProductsDetails/ProductsDetails")
);
const SubscriptionDetails = lazy(() => import("pages/Subscription/Details"));
const NotificationsRealTime = lazy(() =>
  import("components/common/NotificationsRealTime")
);

/** ADMIN SETTING */
const Settings = lazy(() => import("pages/Settings"));
const Roles = lazy(() => import("pages/Settings/Manage/Roles"));
const Masters = lazy(() => import("pages/Settings/Manage/Masters"));
const AboutUs = lazy(() => import("pages/AboutUs/AboutUs"));
const NotFound = lazy(() => import("pages/NotFound/NotFound"));

const App = () => {
  const [{ data: auth }] = useAuth();

  /** DISABLE/RESTRICT DEVELOPER OPTION - IF IT'S NOT 'DEV' ENVIRONMENT */
  useEffect(() => {
    if (
      process.env.REACT_APP_MODE === "production" ||
      process.env.REACT_APP_MODE === "testing"
    ) {
      const checkDevToolsShortcuts = (e) => {
        return (
          e.key === "F12" || // Blocks F12 key
          (e.ctrlKey && e.shiftKey && e.key === "I") || // Block Ctrl + Shift + I
          (e.ctrlKey && e.shiftKey && e.key === "J") || // Block Ctrl + Shift + J
          (e.ctrlKey && e.shiftKey && e.key === "C") || // Block Ctrl + Shift + C
          (e.ctrlKey && e.key === "U") // Block Ctrl + U
        );
      };

      const handleKeyDown = (e) => {
        if (checkDevToolsShortcuts(e)) {
          e.preventDefault();
        }
      };

      const handleContextMenu = (e) => {
        e.preventDefault();
      };

      /** Attach the keydown & contextmenu event listener to block right-click & developer tool shortcuts */
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("contextmenu", handleContextMenu);

      /** Cleanup the event listeners when the component unmounts */
      return () => {
        document.removeEventListener("keydown", handleKeyDown);
        document.removeEventListener("contextmenu", handleContextMenu);
      };
    }
  }, []);

  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        {/* LOGIN */}
        <Route path="/" element={<Login />} />

        {/* HEADER WITH SIDE MENU LAYOUT */}
        <Route path="/" element={<UserLayout />}>
          <Route index path="kanban" element={<Home />} />
          { (auth.details?.isSuperAdmin || auth.details?.roleHierarchy === 1) && (
            <Route index path="dashboard" element={<Dashboard />}/>
          )}
          
          <Route path="ticket">
            <Route path="detail/:workspaceId/:id" element={<Ticket />} />
          </Route>
          <Route path="teams">
            <Route path=":activeTab?" element={<Teams />} />
            <Route
              path=":activeTab?/userprofile/:id"
              element={<UserProfile />}
            />
          </Route>
          {auth.activeWorkSpace === 2 && (
            <Route index path="calendar" element={<Calendar />} />
          )}
          {auth.activeWorkSpace !== 3 && (
            <>
              <Route path="customers">
                <Route path="" element={<Customers />} />
                <Route
                  path="customersdetails/:id"
                  element={<CustomersDetails />}
                />
              </Route>
              <Route path="products">
                <Route path="" element={<Products />} />
                <Route
                  path="productsdetails/:id"
                  element={<ProductsDetails />}
                />
                <Route path="subscription" element={<SubscriptionDetails />} />
              </Route>
              <Route path="settings">
                <Route path="" element={<Settings />} />
                {/* ALLOW PAGE ACCESS ONLY FOR SUPER ADMIN & ADMIN */}
                <Route path="" element={<SettingLayout />}>
                  <Route
                    path="roles"
                    element={
                      auth.details?.isSuperAdmin ||
                      auth.details?.roleHierarchy == 1 ? (
                        <Roles />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />
                  <Route
                    path="masters"
                    element={
                      auth.details?.isSuperAdmin ||
                      auth.details?.roleHierarchy == 1 ? (
                        <Masters />
                      ) : (
                        <Navigate to="/" replace />
                      )
                    }
                  />
                </Route>
              </Route>
            </>
          )}
          <Route path="notification">
            <Route path="detail" element={<Notification />} />
          </Route>
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
      {auth.activeWorkSpace !== 3 && <NotificationsRealTime />}
    </Suspense>
  );
};

export default App;
